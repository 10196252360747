<template>  
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card
            v-if="showTable"
            flat
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode" 
        >
        <V-card-title> {{$store.getters.getTextMap().yesterdays_aqi}} </V-card-title>
            <v-row>
                <v-col>
                    <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                        <template v-slot:default>
                            <thead :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <tr>
                                    <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().areas }}</th>
                                    <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().aqi }}</th>
                                </tr>
                            </thead>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                                <tr v-for="(area,ind) in areas"  :key="ind" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <td  class="text-center">{{ area.area_name }}</td>
                                    <td  class="text-center">{{ dbData&& dbData[area.area_id]&&dbData[area.area_id]['aqi']?dbData[area.area_id]['aqi'].toFixed(2):'-' }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'AreaWiseAQIComponent',
    props:['areas','AQdeviceIdAreaMap'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            info:'',
            showDismissibleAlert:false,
            loading:false,
            dbData:{},
            showTable:false,

        }
    },
    mounted(){
        if(this.AQdeviceIdAreaMap){
            this.fetchData()
        }
    },
    methods:{
        async fetchData(){
            try{
                if(this.areas && this.areas.length>0){
                    this.dbData={}
                let from_date=moment().subtract(1,'days').format("YYYYMMDD")
                let payload={
                    from_date:from_date
                }
                for(let i of this.areas){
                    let aq_ids=[]
                    for(let j of this.AQdeviceIdAreaMap[i.area_id]){
                        aq_ids.push(j.aq_id)
                    }
                    payload['aq_ids']=aq_ids
                    const response= await axios.post(this.$store.state.api+'getAvgAQIDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(response.data.status==='success'){
                        let tempData=response.data.data
                        this.dbData[i.area_id]=tempData[0]
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                }
                if(Object.keys(this.dbData).length>0){
                    this.showTable=true
                }
                }
            }catch(error){
                this.loading=false
                console.error("Error while fetching data:", error);
            }
        },
    },
}
</script>