<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card
            flat
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode" 
        >
            <v-row v-if="showTable">
                <v-col>
                    <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                        <template v-slot:default>
                            <thead :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <tr>
                                    <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().remark}}</th>
                                    <th v-for="(item) in areas"  :key="item.area_id" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{item.area_name}}</th>
                                </tr>
                            </thead>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                                <tr  v-for="(item,ind) in remarks"  :key="ind"   :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <td  :class="getRemarksColorClass(item)+' black--text  pa-1 text-center text-subtitle-2'">
                                        <span >{{item}}</span>
                                    </td>
                                    <td v-for="(area,ind) in areas"  :key="ind" class="text-center">{{AQIData && AQIData[item] && AQIData[item][area.area_id]? AQIData[item][area.area_id]:0}}{{$store.getters.getTextMap().days}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'AQInsightsByAreaComponent',
    props:['timeframe','areas','AQdeviceIdAreaMap'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            info:'',
            showDismissibleAlert:false,
            loading:false,
            dbData:{},
            showTable:false,
            finalAreas:[],
            AQIData:{
                'Good':{},
                'Moderate':{},
                'Unhealthy for Sensitive Groups':{},
                'Unhealthy':{},
                'Very Unhealthy':{},
                'Hazardous':{},
            },
            remarks:[
                'Good',
                'Moderate',
                'Unhealthy for Sensitive Groups',
                'Unhealthy',
                'Very Unhealthy',
                'Hazardous'
            ]
        }
    },
    mounted(){
        if(this.AQdeviceIdAreaMap){
            this.fetchData()
        }
    },
    methods:{
        calculateInsights(){
            this.AQIData={
                'Good':{},
                'Moderate':{},
                'Unhealthy for Sensitive Groups':{},
                'Unhealthy':{},
                'Very Unhealthy':{},
                'Hazardous':{},
             }
            this.finalAreas=[]
            let finalAreas=new Set([])
            for(let j of this.areas){
                for(let i of this.dbData[j.area_id]){
                    if(i["aqi"]!==null){
                        finalAreas.add(j)
                        for(let k of this.remarks){
                            if(!this.AQIData[k][j.area_id]){
                                this.AQIData[k][j.area_id]=0
                            }         
                        }
                    }
                    // else{
                    //     for(let k of this.remarks){
                    //         if(!this.AQIData[k][j.area_id]){
                    //             this.AQIData[k][j.area_id]=""
                    //         }         
                    //     }
                    // }
                    if(i["aqi"] && i["aqi"]<=50){
                        this.AQIData['Good'][j.area_id]=this.AQIData['Good'][j.area_id]+1
                    }else if(i["aqi"] && (i["aqi"]>=51 && i[j.area_id]<=100) ){
                        this.AQIData['Moderate'][j.area_id]=this.AQIData['Moderate'][j.area_id]+1
                    }else if(i["aqi"] && (i[j.area_id]>=101 && i["aqi"]<=150 )){
                        this.AQIData['Unhealthy for Sensitive Groups'][j.area_id]=this.AQIData['Unhealthy for Sensitive Groups'][j.area_id]+1
                        // this.AQIData['Unhealthy for Sensitive Groups'][j.area_id]=this.AQIData['Unhealthy for Sensitive Groups'][j.area_id]=1
                    }else if(i["aqi"] && (i[j.area_id]>=151 && i["aqi"]<=200) ){
                        this.AQIData['Unhealthy'][j.area_id]=this.AQIData['Unhealthy'][j.area_id]+1
                    }else if(i["aqi"] && (i[j.area_id]>=201 && i["aqi"]<=300) ){
                        this.AQIData['Very Unhealthy'][j.area_id]=this.AQIData['Very Unhealthy'][j.area_id]+1
                    }else if(i["aqi"] && i["aqi"]>=301){
                        this.AQIData['Hazardous'][j.area_id]=this.AQIData['Hazardous'][j.area_id]+1
                    }
                }
            }
            this.finalAreas=[...finalAreas]
            this.showTable=true
        },
        async fetchData(){
            try{
                if(this.areas && this.areas.length>0 && this.AQdeviceIdAreaMap){
                    this.dbData={}
                let from_date=moment().subtract(parseFloat(this.timeframe),'days').format("YYYYMMDD")
                let payload={
                    from_date:from_date
                }
                for(let i of this.areas){
                    let aq_ids=[]
                    for(let j of this.AQdeviceIdAreaMap[i.area_id]){
                        aq_ids.push(j.aq_id)
                    }
                    payload['aq_ids']=aq_ids
                    const response= await axios.post(this.$store.state.api+'getAQIDataGroupedByDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(response.data.status==='success'){
                        let tempData=response.data.data
                        this.dbData[i.area_id]=tempData
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                }
                this.calculateInsights()
            }
            }catch(error){
                this.loading=false
                console.error("Error while fetching data:", error);
            }
        },
        getRemarksColorClass(remark) {
            switch(remark){
                case "Good":
                    return 'green accent-3'
                case "Moderate":
                    return 'lime accent-3'
                case "Unhealthy for Sensitive Groups":
                    return 'yellow lighten-2'
                case "Unhealthy":
                    return 'yellow darken-3'
                case "Very Unhealthy":
                    return 'orange darken-4'
                case "Hazardous":
                    return 'red darken-3'
            }
      },
    },
    watch:{
        timeframe(){
            this.fetchData()
        },
    }
}
</script>