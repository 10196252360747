<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card
            flat
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode" 
        >
            <v-row v-if="showTable && pollutants && pollutants.length>0">
                <v-col>
                    <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                        <template v-slot:default>
                            <thead :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <tr>
                                    <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().areas}}</th>
                                    <th v-for="(item,ind) in pollutants"  :key="ind" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{referenceNames[item]}}</th>
                                </tr>
                            </thead>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                                <tr v-for="(area,ind) in areas"  :key="ind" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <td  class="text-center">{{ area.area_name }}</td>
                                    <td  v-for="(pollutant,ind) in pollutants"  :key="ind" class="text-center">{{ dbData[area.area_id][pollutant]?dbData[area.area_id][pollutant].toFixed(2):'-' }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'AQIHistoricalDataTableComponent',
    props:['timeframe','areas','AQdeviceIdAreaMap'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            info:'',
            showDismissibleAlert:false,
            loading:false,
            dbData:{},
            showTable:false,
            pollutant:[],
            references:[
                'pm10',
                'pm25',
                'co',
                'co2',
                'so2',
                'no2',
                'voc',
                'o3',
                'no',
                'humidity',
                'temp',
                'aqi'
            ],
            referenceNames:{
                'pm10':'PM10',
                'pm25':'PM2.5',
                'co':'CO',
                'co2':'CO2',
                'so2':'SO2',
                'no2':'NO2',
                'voc':'VOC',
                'o3':'O3',
                'no':'NO',
                'humidity':'Humidity',
                'temp':'Temperature',
                'aqi':'AQI'
            },
        }
    },
    mounted(){
        if(this.AQdeviceIdAreaMap){
            this.fetchData()
        }
    },
    methods:{
        filterData(){
            this.pollutants=[]
            let pollutants=new Set([])
            for(let i of this.areas){
                for(let j of this.references){
                    if(this.dbData[i.area_id][j] !== null){
                        pollutants.add(j)
                    }
                }
            }
            this.pollutants=[...pollutants]
            this.showTable=true
        },
        async fetchData(){
            try{
                if(this.areas && this.areas.length>0){
                    this.pollutants=[]
                    this.showTable=false
                    this.dbData={}
                    // let from_date=moment().subtract(parseFloat('1'),'days').format("YYYYMMDD")
                    let from_date=moment().subtract(parseFloat(this.timeframe),'days').format("YYYYMMDD")
                    let payload={
                        from_date:from_date
                    }
                    for(let i of this.areas){
                        let aq_ids=[]
                        for(let j of this.AQdeviceIdAreaMap[i.area_id]){
                            aq_ids.push(j.aq_id)
                        }
                        payload['aq_ids']=aq_ids
                        const response= await axios.post(this.$store.state.api+'getAvgAQIDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                        if(response.data.status==='success'){
                            let tempData=response.data.data
                            this.dbData[i.area_id]=tempData[0]
                        }else{
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }
                    this.filterData()
                }
            }catch(error){
                this.loading=false
                console.error("Error while fetching data:", error);
            }
        },
    },
    watch:{
        timeframe(){
            this.fetchData()
        },
    }
}
</script>