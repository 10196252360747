<template>
  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info"/>
    <v-form :dark="$store.getters.getColorPalette().isDark" ref="aqForm" v-model="valid">
      <v-card class="px-5" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-card-title class="sub-heading">{{ $store.getters.getTextMap().select_area}}</v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
        <v-row no-gutters>
          <v-col cols="auto" class="d-flex" align="right">
            <v-checkbox class="mx-2" v-model="enableScroll" :label="$store.getters.getTextMap().enable_Scroll_mode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().checkboxColor" />
            <v-checkbox v-model="multipleAreas" :label="$store.getters.getTextMap().multiple_areas" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().checkboxColor"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col v-if="multipleAreas">
            <v-autocomplete
              v-if="!loading"
              v-model="area"
              :items="areaOptions"
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              outlined
              dense
              :label="$store.getters.getTextMap().areas"
              multiple
              item-text="label"
              item-value="value"
              :rules="objectRules"
            ></v-autocomplete>
          </v-col>
          <v-col v-else>
            <v-autocomplete
              v-if="!loading"
              v-model="area"
              :items="areaOptions"
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              outlined
              dense
              :label="$store.getters.getTextMap().area"
              item-text="label"
              item-value="value"
              :rules="objectRules"
            ></v-autocomplete>
          </v-col>
      </v-row>
      <v-row no-gutters class="pb-5">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="d-flex" align="right">
          <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled class="mx-auto" @click="send">{{  $store.getters.getTextMap().submit}}</v-btn>
          <v-btn class="ml-1" small :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="$emit('close')">{{$store.getters.getTextMap().cancel}}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
  </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
  name: 'AQAreaForm',
  props: ['multiple', 'areaData'],
  components: {
    InfoAlert,
  },
  data() {
    return {
        enableScroll:false,
        multipleAreas:true,
        showDialog: false,
        valid:false,
        loading: false,
        info: '',
        showDismissibleAlert: false,
        area: [],
        form: {},
        areaOptions: [],
        rules: FormRules.rules,
        objectRules: [v => !!v || 'Required'],
    }
  },
  mounted() {
    this.area=null
    if(this.multiple){
      this.area = []
    }else{
      this.area=null
    }
      this.multipleAreas=this.multiple
    // if(this.multiple!==null){
    //   this.multipleAreas=this.multiple
    // }
    this.area = this.areaData
    this.enableScroll = localStorage.getItem('enableScroll') === 'true'; 
    this.getAreasList()
  },
  methods: {
    getAreasList() {
      let op = []
      this.areaOptions = []
      this.loading = true
      axios.get(this.$store.state.api + 'areas', {headers: { Authorization: 'Bearer ' + this.$store.state.jwt }})
      .then(response => {
        if (response.data.status == 'success') {
          let areas = response.data.areas
          for (let i of areas) {
            if (i && i.name) {
              op.push({ value: { area_id: i.area_id, area_name: i.name }, label: i.name })
            } else {
              op.push({ value: i.area_id, label: i.area_id })
            }
          }
        } else {
          this.info = response.data.msg
          this.showDismissibleAlert = true
        }
        this.loading = false
      })
      .catch(error => {
        this.info = error
        this.showDismissibleAlert = true
        this.loading = false
      })
      this.areaOptions = op
    },
    send() {
      this.$refs.aqForm.validate()
      if(this.valid && this.multipleAreas?(this.area && this.area.length>0):this.area){
        this.form.area = this.area
         if (this.enableScroll) {
          this.form.enableScroll = this.enableScroll;
          localStorage.setItem('enableScroll', 'true'); 
        }else{
          this.form.enableScroll = false;
          localStorage.setItem('enableScroll', 'false'); 
        }
        this.form['multipleAreas']=this.multipleAreas
        this.$emit('value', this.form)
      }else{
        this.info="Please select area"
        this.showDismissibleAlert=true
      }
    },
  },
  watch:{
    multipleAreas(){
      if(this.multipleAreas){

        this.area=[]
      }else{
        this.area=null
      }
    }
  }
}
</script>
