<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col cols="10">
                <!-- <v-card  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"> -->
                <v-row>
                    <v-col cols="4">
                        <v-card elevation="1" class="mb-4"  flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                <v-card-subtitle class="text-center" :style="{color: $store.getters.getColorPalette().btnborderColorCode}">
                                    <h2>{{$store.getters.getTextMap().area}}</h2>
                                    <h2 :style="{marginTop:'15px',color: $store.getters.getColorPalette().accentCode}">{{area.area_name}}</h2>
                                </v-card-subtitle>
                            </v-card>
                        <v-row>
                            <v-col>
                                    <v-card elevation="1"  flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                    <div class="chart-containe">
                                        <apexchart
                                            ref="fullGauge"
                                            type="radialBar"
                                            :options="getFullGaugeOptions(avgAQDBData && avgAQDBData['aqi']?avgAQDBData['aqi'].toFixed(2):0 )"
                                            :series="[avgAQDBData && avgAQDBData['aqi']?avgAQDBData['aqi'].toFixed(2):0 ]"
                                        ></apexchart>
                                    </div>
                                </v-card>
                                </v-col>
                            </v-row>
                    </v-col>
                    <v-divider vertical :dark="$store.getters.getColorPalette().isDark"></v-divider>
                    <v-col cols="8">
                            <v-row>
                                <v-col v-for="(item,ind) in Object.keys(pollutants1)" :key="ind" cols="4">                            
                                    <v-card elevation="1"  flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                        <v-card-subtitle class="text-center" :style="{color: $store.getters.getColorPalette().accentCode,fontSize: '20px'}">
                                            {{ referenceNames[item] }}
                                        </v-card-subtitle>                
                                        <div class="chart-container">
                                            <apexchart
                                                ref="semiGauge"
                                                type="radialBar"
                                                :options="getSemiGaugeOptions(item)"
                                                :series="[streamVal(item,pollutants1[item])]"
                                            ></apexchart>
                                        </div>
                                        <!-- <div class="chart-container">
                                            <apexchart
                                                ref="semiGauge"
                                                type="radialBar"
                                                :options="getSemiGaugeOptions(item)"
                                                :series="[getStreamdataPercentage(item)]"
                                            ></apexchart>
                                        </div> -->
                                    </v-card>
                                </v-col>
                            </v-row>
                            <!-- <v-divider></v-divider> -->
                            <v-row>
                                <v-col v-for="(item,ind) in Object.keys(pollutants2) " :key="ind" cols="4"> 
                                    <v-card elevation="1"  flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                        <v-card-subtitle class="text-center" :style="{color: $store.getters.getColorPalette().accentCode,fontSize: '20px'}">
                                            {{ referenceNames[item] }}
                                        </v-card-subtitle>                          
                                        <div class="chart-container">
                                            <apexchart
                                                ref="semiGauge"
                                                type="radialBar"
                                                :options="getSemiGaugeOptions(item)"
                                                :series="[streamVal(item,pollutants2[item])]"
                                            ></apexchart>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>  
                <!-- </v-card> -->
                <!-- <v-card class="mt-4" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"> -->
                    <v-divider :dark="$store.getters.getColorPalette().isDark" class="my-4"></v-divider>    
                        <v-row>
                            <v-col>
                                <v-card elevation="1" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                <div class="chart-containe">
                                        <apexchart
                                            ref="heatMap"
                                            type="heatmap"
                                            :options="hearMapOptions"
                                            :series="heatmapGraphData"
                                        ></apexchart>
                                    </div>
                                    </v-card>
                            </v-col>
                        </v-row>  
                        <!-- <v-divider class="mt-4" :dark="$store.getters.getColorPalette().isDark"></v-divider> -->
                        <v-row>
                            <v-col>
                                <v-card elevation="1"  flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                <v-row>
                                    <v-col class="ml-5 mt-5">
                                        <v-autocomplete
                                            v-if="!loading"
                                            v-model="pollutantForGraph"
                                            :items="pollutantOptions"
                                            :dark="$store.getters.getColorPalette().isDark"
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                                            outlined
                                            dense
                                            :label="$store.getters.getTextMap().pollutants"
                                            item-text="label"
                                            item-value="value"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col class="mr-5  mt-5" align="right">
                                        <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="barGraphType" mandatory rounded>
                                            <v-btn small>{{$store.getters.getTextMap().bar}}r</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <div ref="temperature" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}" ></div>
                                </v-card>
                            </v-col>
                        </v-row> 
                    <!-- </v-card> -->
                </v-col>
                <v-divider vertical :dark="$store.getters.getColorPalette().isDark"></v-divider>
                <v-col cols="2" align="center">
                    <h3 :style="{color:$store.getters.getColorPalette().accentCode+'98'}">Last 7 Days</h3>
                    <!-- <v-card  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"> -->
                        <v-row  v-for="(item) in Object.keys(avgPollutantData) " :key="item">
                            <v-col v-if=" avgPollutantData && avgPollutantData[item]" >
                                    <v-card elevation="1" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                    <v-card-subtitle class="text-center" :style="{color: $store.getters.getColorPalette().btnborderColorCode}">
                                        <h2>{{'Avg '+item}}</h2>
                                        <h1 :style="{marginTop:'15px',color: $store.getters.getColorPalette().accentCode}">{{ avgPollutantData && avgPollutantData[item]?avgPollutantData[item].toFixed(2):'- ' }}</h1>
                                    </v-card-subtitle>
                                </v-card>
                                    <!-- <v-divider></v-divider> -->
                                </v-col>
                        </v-row>
                    <!-- </v-card> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import Plotly from 'plotly.js'
import VueApexCharts from "vue-apexcharts";
import moment from 'moment'
import axios from 'axios'
import {io} from 'socket.io-client';
export default {
    name:'AQOverviewComponent',
    props:['timeframe','area','air_quality','AQdeviceIdAreaMap'],
    components:{
        InfoAlert,
        apexchart: VueApexCharts,
    },
    data(){
        return{
            info:'',
            showDismissibleAlert:false,
            loading:false,
            stream:null,
            streamData:{},
            aqDeviceOption:[],
            aq_device:null,
            barGraphType:1,
            barGraphData:{},
            avgAQDBData:{},
            avgPollutantDBDataByAqId:{},
            avgPollutantData:{},
            barGraphDBData:[],
            heatmapDBData:[],
            heatmapGraphData:[],
            pollutants1:{},
            pollutants2:{},
            pollutantForGraph:'temp',
            pollutantOptions:[
                {label:'PM10',value:'pm10'},
                {label:'PM2.5',value:'pm25'},
                {label:'CO',value:'co'},
                {label:'CO2',value:'co2'},
                {label:'SO2',value:'so2'},
                {label:'NO2',value:'no2'},
                {label:'VOC',value:'voc'},
                {label:'O3',value:'o3'},
                {label:'NO',value:'no'},
                {label:'Humidity',value:'humidity'},
                {label:'Temperature',value:'temp'},
            ],
            references:[
                'pm10',
                'pm25',
                'co',
                'co2',
                'so2',
                'no2',
                'voc',
                'o3',
                'no',
                'humidity',
                'temp',
            ],
            references1:[
                'pm10',
                'pm25',
                'voc',
            ],
            references2:[
                'co2',
                'temp',
                'humidity',
            ],
            referenceVals:{
                'pm10':430,
                'pm25':250,
                'co2':3000,
                'temp':80,
                'humidity':100,
                'voc':1000,
                'so2':0.61,
                'no2':0.213,
                'o3':0.383,
                'no':1000,
            },
            // referenceVals:{
            //     'pm10':100,
            //     'co2':3000,
            //     'temp':80,
            //     'humidity':100,
            //     'voc':1000,
            //     'so2':1000,
            //     'no2':1000,
            //     'o3':1000,
            //     'no':1000,
            // },
            referenceNames:{
                'aqi':'AQI',
                'pm10':'PM10',
                'pm25':'PM2.5',
                'co':'CO',
                'co2':'CO2',
                'so2':'SO2',
                'no2':'NO2',
                'voc':'VOC',
                'o3':'O3',
                'no':'NO',
                'humidity':'Humidity',
                'temp':'Temp',
            },
            hourMap:{
                0:'12 AM',
                1:'1 AM',
                2:'2 AM',
                3:'3 AM',
                4:'4 AM',
                5:'5 AM',
                6:'6 AM',
                7:'7 AM',
                8:'8 AM',
                9:'9 AM',
                10:'10 AM',
                11:'11 AM',
                12:'12 PM',
                13:'1 PM',
                14:'2 PM',
                15:'3 PM',
                16:'4 PM',
                17:'5 PM',
                18:'6 PM',
                19:'7 PM',
                20:'8 PM',
                21:'8 PM',
                22:'10 PM',
                23:'11 PM',
            },
            heatMapSeries: [
                    {
                    name: "Series 1",
                    data: [{
                        x: 'W1',
                        y: 22
                    }, {
                        x: 'W2',
                        y: 150
                    }, {
                        x: 'W3',
                        y: 13
                    }, {
                        x: 'W4',
                        y: 302
                    }]
                    },
                    {
                    name: "Series 2",
                    data: [{
                        x: 'W1',
                        y: 43
                    }, {
                        x: 'W2',
                        y: 60
                    }, {
                        x: 'W3',
                        y: 43
                    }, {
                        x: 'W4',
                        y: 43
                    }]
                    }
                ],
            hearMapOptions: {
                chart: {
                    height: 350,
                    type: 'heatmap',
                    foreColor:this.$store.getters.getColorPalette().accentCode
                },
                plotOptions: {
                    heatmap: {
                        colorScale: {
                        ranges: [{
                                from: 0,
                                to: 50,
                                color: '#00E676',
                                name: '0-50',
                            },
                            {
                                from: 51,
                                to: 100,
                                color: '#C6FF00',
                                name: '51-100',
                            },
                            {
                                from: 101,
                                to: 150,
                                color: '#FFF176',
                                name: '101-150',
                            },
                            {
                                from: 151,
                                to: 200,
                                color: '#F9A825',
                                name: '151-200',
                            },
                            {
                                from: 201,
                                to: 300,
                                color: '#E65100',
                                name: '201-300',
                            },
                            {
                                from: 301,
                                to: 1000,
                                color: '#F44336',
                                name: '301 & Above',
                            }
                        ]
                        },
                    } 
                },
                tooltip:{theme:'dark'},
                dataLabels: {
                    enabled: false,
                },
                    title: {
                    text: 'Air Quality Index Hourly Health'
                },
            },
            layout : {
                barmode: this.barMode,
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                legend:{
                    y:-30
                },
                plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                showlegend:true,
                autosize: true,
                xaxis: {
                    tickangle: -65,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted(){
        if(this.AQdeviceIdAreaMap){
            this.getAvgPollutantDataData()
            this.getHeatmapData()
            this.getBarGraphData()
            this.createAQDeviceOptions()
            this.getAvgAQIData()
            this.aq_device=this.air_quality[0]
        }
    },
    created(){
        this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
    },
    beforeDestroy() {
        this.stream.close()
    },
    computed:{
        fromTimeStamp(){
            let from_date = moment().subtract(8,'days').format('YYYYMMDD');
            let dt=from_date.toString()
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
        toTimeStamp(){
            let to_date = moment().subtract(0,'days').format('YYYYMMDD');
            let dt=to_date.toString()
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
    },
    methods:{
        getSemiGaugeOptions(key){
            let referenceVal=this.referenceVals[key]?this.referenceVals[key]:1
            return {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                 },
                 colors: ["#00E676"],
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        ranges:[0,1000],
                        track: {
                            background: this.$store.getters.getColorPalette().background2ColorCode,
                            strokeWidth: '70%',
                            // margin: 5,
                            dropShadow: {
                                enabled: true,
                                // top: 2,
                                left: 0,
                                color: this.$store.getters.getColorPalette().accentCode,
                                // opacity: 1,
                                // blur: 2
                            }
                        },
                        dataLabels: {
                            min: 0,
                            max: 1000,
                            name: {show: false},
                            value: {
                                formatter: function(streamVal) {
                                    if (isNaN(streamVal)){
                                        return 0
                                    }
                                    return ((parseFloat(streamVal)*referenceVal)/100).toFixed(2);
                                },
                                offsetY: -2,
                                fontSize: '18px',
                                color: this.$store.getters.getColorPalette().accentCode,
                            }
                        }
                    }
                },
                // grid: {padding: {top: -10}},
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91]
                    },
                },
            }
        },
        getFullGaugeOptions(streamVal){
            let gaugeColor="#F44336"
            if(streamVal<=50){
                gaugeColor="#00E676"
            }else if(streamVal>=51 && streamVal<=100){
                gaugeColor="#C6FF00"
            }else if(streamVal>=101 && streamVal<=150){
                gaugeColor="#FFF176"
            }else if(streamVal>=151 && streamVal<=200){
                gaugeColor="#F9A825"
            }else if(streamVal>=201 && streamVal<=300){
                gaugeColor="#E65100"
            }else{
                gaugeColor="#F44336"
            }
            return {
                chart: {
                    type: 'radialBar',
                },
                colors: [
                gaugeColor,
                ],
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                            margin: 0,
                            size: '60%',
                            background:this.$store.getters.getColorPalette().background2ColorCode,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background:this.$store.getters.getColorPalette().accentCode,
                            strokeWidth: '97%',
                            margin: 0,
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -15,
                                show: true,
                                color: this.$store.getters.getColorPalette().accentCode,
                                fontSize: '25px'
                            },
                            value: {
                                formatter: function(val) {
                                    return parseFloat(val);
                                },
                                offsetY: 15,
                                show: true,
                                color: this.$store.getters.getColorPalette().accentCode,
                                fontSize: '25px',
                            }
                        }
                    }
                },
                fill: {
                    // type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5, 
                        gradientToColors: ['#C62828'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 1000]
                    }
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: ['AQI'],
            }
        },
        createAQDeviceOptions(){
            let op=[]
            for(let i  of this.air_quality){
                op.push({label:i.label,value:i})
            }
            this.aqDeviceOption=op
        },
        startStream(){
            this.stream.on("connect_error", (err) => {
                console.log(err.message);
            });
            this.stream.on('error',data=>{
                console.error(data)
            })
            this.stream.on('param',data=>{
                if(data.constructor==String){
                    data=JSON.parse(data)
                }
                if(!('param_id' in data)){
                    data.param_id=data.parameter
                }
                let temp=Object.assign({},this.streamData)
                temp[data.param_id]=Math.round(Number(data.data)*1000)/1000
                this.streamData=temp
                this.pollutants1=[]
                this.pollutants2=[]
                let pollutants1={}
                let pollutants2={}
                // let pollutants1=new Set([])
                // let pollutants2=new Set([])
                for(let i of this.air_quality){
                    for(let j of this.references1){
                        if(this.streamData[i[j]]){
                            // pollutants1.add({j:i[j]})
                            if(!pollutants1[j]){
                                pollutants1[j]=[]
                            }
                            pollutants1[j].push(i[j])
                        }
                    }
                    for(let j of this.references2){
                        if(this.streamData[i[j]]){
                            // pollutants2.add({j:i[j]})
                            if(!pollutants2[j]){
                                pollutants2[j]=[]
                            }
                            pollutants2[j].push(i[j])
                        }
                    }
                }
                this.pollutants1=pollutants1
                this.pollutants2=pollutants2
                // this.pollutants1=[...pollutants1]
                // this.pollutants2=[...pollutants2]
            })
            this.subscribe()
        },
        subscribe(){
            for(let i of this.air_quality){
                if(i.pm10){
                    this.stream.emit('getParam',i.pm10)
                }
                if(i.pm25){
                    this.stream.emit('getParam',i.pm25)
                }
                if(i.co2){
                    this.stream.emit('getParam',i.co2)
                }
                if(i.voc){
                    this.stream.emit('getParam',i.voc)
                }
                if(i.so2){
                    this.stream.emit('getParam',i.so2)
                }
                if(i.co){
                    this.stream.emit('getParam',i.co)
                }
                if(i.o3){
                    this.stream.emit('getParam',i.o3)
                }
                if(i.no2){
                    this.stream.emit('getParam',i.no2)
                }
                if(i.temp){
                    this.stream.emit('getParam',i.temp)
                }
                if(i.humidity){
                    this.stream.emit('getParam',i.humidity)
                }
            }
        },
        initBarGraph(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.barGraphType==1?'line':'bar'
                this.barGraphData={}
                for(let i of this.air_quality ){
                    this.barGraphData[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        streamVal(key,param_ids){
            let v=[]
            let avg=0
            for(let i of param_ids){
                let result = this.getStreamdataPercentageTemp(key,i)
                v.push(result)
            }
            let total=v.reduce((a, b) => a + b, 0)
            let length=v.length
            avg=total/(length>0?length:1)
            return avg
        },
        getStreamdataPercentage(item){
            let streamVal=0
            if(this.streamData  && this.aq_device[item] && this.streamData[this.aq_device[item]] && this.referenceVals[item]){
                streamVal=this.streamData[this.aq_device[item]]/this.referenceVals[item]
            }
            return streamVal*100
        },
        getStreamdataPercentageTemp(key,param_id){
            let streamVal=0
            if(this.streamData  && param_id && this.streamData[param_id] && this.referenceVals[key]){
                streamVal=this.streamData[param_id]/this.referenceVals[key]
            }
            return streamVal*100
        },
        createHeatMap(){
            let counts=[8,7,6,5,4,3,2,1,0]
            this.heatmapGraphData=[]
            // let from = moment().subtract(3,'days').format('YYYYMMDD');
            // let to = moment().subtract(1,'days').format('YYYYMMDD');
            let heatMapDataTemp=[]
            for(let i=0; i<24; i += 2){
                let temp={}
                temp['name']=this.hourMap[i]
                let data=[]
                for(let j of counts){
                    for(let k of this.heatmapDBData){
                        if((moment(k.hour).utc().hour()===i) && (moment().subtract(j,'days').format('DD-MM-YYYY')==moment(k.hour).utc().format('DD-MM-YYYY'))){
                            data.push({x:moment().subtract(j,'days').format('DD-MM-YYYY'),y:k && k.aqi && !isNaN(k.aqi)?k.aqi.toFixed(2):0})
                        }
                    }
                }  
                temp['data']=data
                heatMapDataTemp.push(temp)
            }
            this.heatmapGraphData=heatMapDataTemp
        },
        getHeatmapData(){
            if(this.AQdeviceIdAreaMap && this.AQdeviceIdAreaMap[this.area.area_id]){
                let payload={
                    from_timestamp:this.fromTimeStamp,
                    to_timestamp:this.toTimeStamp,
                    aq_ids:[],
                }
                let aq_ids=[]
                for(let j of this.AQdeviceIdAreaMap[this.area.area_id]){
                    aq_ids.push(j.aq_id)
                }
                payload['aq_ids']=aq_ids
                axios.post(this.$store.state.api+'getAvgAQIDataGroupedByHour',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.heatmapDBData=response.data.data
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                })
                .catch(err=>{
                    console.error(err)
                    this.loading=false
                });
            }
        },
        createBarGraph(){
            this.initBarGraph()
            this.layout['title']=this.referenceNames[this.pollutantForGraph]
            this.layout['showlegend']=true
                for(let i of this.barGraphDBData){
                    if(i['label']===this.referenceNames[this.pollutantForGraph]){
                        if(!(i['device'] in this.barGraphData)){
                            let type=this.barGraphType==1?'line':'bar'
                            this.barGraphData[i['device']]={
                            x: [],
                            y: [],
                            name:i['device'],
                            type:type,
                            opacity:0.9,
                            marker: {
                                line: {
                                color: this.$store.getters.getColorPalette().accentCode,
                                width: 1
                                },
                                }
                            }
                        }
                        this.barGraphData[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                        this.barGraphData[i['device']]['y'].push(i.usage)
                    }
                } 
                if(this.air_quality && this.air_quality.length>0 && Object.values(this.barGraphData) && (Object.values(this.barGraphData)).length>0){
                    Plotly.newPlot(this.$refs.temperature, Object.values(this.barGraphData), this.layout, {displaylogo: false});
                }
        },
        getBarGraphData(){
            this.loading=true
            this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
            this.graphData={}
            let from_date=moment().subtract(parseFloat(this.timeframe),'days').format("YYYYMMDD")
            let payload={
                from_date:from_date,
                parameters:[],
            }
            let labelMap={}
            let deviceMap={}
            for(let i of this.air_quality){
                payload.parameters.push(i[this.pollutantForGraph])
                labelMap[i[this.pollutantForGraph]]=this.referenceNames[this.pollutantForGraph]
                deviceMap[i[this.pollutantForGraph]]=i.label
            }
            axios.post(this.$store.state.api+'getParamGroupedUsageDataByTimeframe',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.barGraphDBData=response.data.data
                    this.barGraphDBData.map(x=>{
                    x.label=labelMap[x.param_id]?labelMap[x.param_id]:'Label Not defined'
                    x.device=deviceMap[x.param_id]?deviceMap[x.param_id]:'Device Not define'
                })
                this.createBarGraph()
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            })
            .catch(err=>{
                console.error(err)
                this.loading=false
            });
        },
        calculateAvgForPollutants(){
            let temp={}
            let avgData={}
            this.avgPollutantData={}
            for(let i of Object.keys(this.avgPollutantDBDataByAqId)){
                for(let j of this.avgPollutantDBDataByAqId[i]){
                    if(!temp[j.label]){
                        temp[j.label]=[]
                    }
                    temp[j.label].push(j.avg?j.avg:0)
                }
            }
            for(let v of Object.keys(temp)){
                let length=temp[v].length
                let total=temp[v].reduce((a, b) => a + b, 0)
                let avg=total/(length>0?length:1)
                avgData[v]=avg
            }
            this.avgPollutantData=avgData
        },
        async getAvgPollutantDataData(){
            try{
                this.loading=true
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
                let from_date=moment().subtract(parseFloat(this.timeframe),'days').format("YYYYMMDD")
                this.avgPollutantDBDataByAqId={}
                let dict={}
                for(let i of this.air_quality){
                    let temp=[]
                    let payload={
                        from_date:from_date,
                        parameters:[],
                        groupByDate:false
                    }
                    let labelMap={}
                    let deviceMap={}
                    let parameters=[i.co,i.co2,i.no,i.no2,i.o3,i.pm10,i.pm25,i.so2,i.voc,i.temp,i.humidity]
                    parameters = parameters.filter(item => item !== null);
                    payload.parameters=parameters
                    if(i.co){
                        labelMap[i.co]="CO"
                        deviceMap[i.co]=i.label
                    }
                    if(i.co2){
                        labelMap[i.co2]="CO2"
                        deviceMap[i.co2]=i.label
                    }
                    if(i.no){
                        labelMap[i.no]="NO"
                        deviceMap[i.no]=i.label
                    }
                    if(i.no2){
                        labelMap[i.no2]="NO2"
                        deviceMap[i.no2]=i.label
                    }
                    if(i.o3){
                        labelMap[i.o3]="O3"
                        deviceMap[i.o3]=i.label
                    }
                    if(i.pm10){
                        labelMap[i.pm10]="PM10"
                        deviceMap[i.pm10]=i.label
                    }
                    if(i.pm25){
                        labelMap[i.pm25]="PM2.5"
                        deviceMap[i.pm25]=i.label
                    }
                    if(i.so2){
                        labelMap[i.so2]="SO2"
                        deviceMap[i.so2]=i.label
                    }
                    if(i.voc){
                        labelMap[i.voc]="VOC"
                        deviceMap[i.voc]=i.label
                    }
                    if(i.temp){
                        labelMap[i.temp]="Temp"
                        deviceMap[i.temp]=i.label
                    }
                    if(i.humidity){
                        labelMap[i.humidity]="Humidity"
                        deviceMap[i.humidity]=i.label
                    }
                    // labelMap[i[this.pollutantForGraph]]=this.referenceNames[this.pollutantForGraph]
                    // deviceMap[i[this.pollutantForGraph]]=i.label
                    let response = await axios.post(this.$store.state.api+'getParamDailyDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(response.data.status==='success'){
                        temp=response.data.data
                        temp.map(x=>{
                            x.label=labelMap[x.param_id]?labelMap[x.param_id]:'Label Not defined'
                            x.device=deviceMap[x.param_id]?deviceMap[x.param_id]:'Device Not define'
                        })
                        dict[i.aq_id]=temp
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }
                this.avgPollutantDBDataByAqId=dict
                this.loading=false
            }catch(error){
                this.loading=false
                this.info=error
                this.showDismissibleAlert=true
            }
        },
        async getAvgAQIData(){
            try{
                this.avgAQDBData={}
            let from_date=moment().subtract(parseFloat(this.timeframe),'days').format("YYYYMMDD")
            let payload={
                from_date:from_date
            }
            let aq_ids=[]
            for(let j of this.AQdeviceIdAreaMap[this.area.area_id]){
                aq_ids.push(j.aq_id)
            }
            payload['aq_ids']=aq_ids
            const response= await axios.post(this.$store.state.api+'getAvgAQIDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            if(response.data.status==='success'){
                let tempData=response.data.data[0]
                this.avgAQDBData=tempData
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }catch(error){
                this.loading=false
                console.error("Error while fetching data:", error);
            }
        },
    },
    watch:{
        timeframe(){
            this.getAvgAQIData()
        },
        aq_device(){
            this.getAvgAQIData()
        },
        avgAQDBData(){
            this.startStream()
            this.$refs.fullGauge
            this.$refs.semiGauge
        },
        heatmapDBData(){
            this.createHeatMap()
        },
        barGraphType: function (){
            this.createBarGraph()
        },
        pollutantForGraph: function (){
            this.getBarGraphData()
        },
        avgPollutantDBDataByAqId: function (){
            this.calculateAvgForPollutants()
        },
    }
}
</script>
