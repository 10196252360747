<template>
    <v-container @contextmenu="show" fluid class="px-0 py-0" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <LoadingComponent :loading="loading"/>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-container v-if="settingOptions">
            <v-row v-if="showEdit && $store.state.user && $store.state.settingMode ">
                <v-col cols="auto" class="d-flex">
                    <v-chip
                        v-if="
                        $store.state.user &&
                        $store.state.settingMode &&
                        $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        ><v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{
                            color: $store.getters.getColorPalette().background2ColorCode,
                        }"
                        :class="
                            $store.getters.getColorPalette().foregroundColorName +
                            ' mx-0 px-0'
                        "
                        ><v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-identifier</v-icon></v-avatar>
                        {{ view.view_id }}
                        </v-chip>
            </v-col>
                <v-col cols="auto" class="d-flex">
                    <v-btn class="mr-1" @click="setAsDefault" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().add_to_dashboard }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-eye-arrow-left</v-icon></v-btn>
                    <EditViewBottomSheet :view="view"/>
                </v-col>
                <v-col  cols="auto" class="d-flex ml-auto" align="right">
                    <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="init">
                        {{ $store.getters.getTextMap().refresh }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>
                            mdi-refresh
                        </v-icon>
                    </v-btn>
                    <DeleteConfirmation v-on:confirm="deleteView()"  title="Delete Confirmation"  description="Are you sure you want to delete this View?"> 
                        <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete_view }}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                         </v-btn>
                    </DeleteConfirmation>
                </v-col>
            </v-row>
        </v-container>
        <v-row>
            <v-col>
                <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y v-if="edit">
                    <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                        <v-list-item @click="dialog=true">
                            <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
                            <v-list-item-icon class="pt-2">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row no-gutters  v-if="areaData && (areaData.area_id || areaData.length>0) && showComponent && view && view.view_id"  :class="{ 'card-content-scroll': enableScrollMode }">
            <v-col>
                <div v-if="multipleAreas">
                <v-card
                    v-if="areaData && areaData.length>0 && AQdeviceIdAreaMap"
                    class="pa-3" 
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                >
                    <v-row no-gutters>
                        <v-col>
                            <v-select  :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                v-if="!loading"
                                v-model="timeframe"
                                :items="timeframeOptions"
                                label="Time Frame"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"   
                            ></v-select>  
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <AQInsightsByAreaComponent :timeframe="timeframe" :areas="areaData" :AQdeviceIdAreaMap="AQdeviceIdAreaMap"/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <AQIHistoricalDataTableComponentVue :timeframe="timeframe" :areas="areaData" :AQdeviceIdAreaMap="AQdeviceIdAreaMap"/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <PollutantsGraphComponent :air_quality="AQDeviceList" :timeframe="timeframe"/>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card
                    v-if="areaData && areaData.length>0 && AQdeviceIdAreaMap"
                    class="mt-3"
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" 
                >
                    <AreaWiseAQIComponent :areas="areaData" :AQdeviceIdAreaMap="AQdeviceIdAreaMap"/>
                </v-card>
            </div>
            <div v-else>
                <v-card
                    v-if="areaData && areaData.area_id && AQDeviceList && AQDeviceList.length>0 && AQdeviceIdAreaMap"
                    class="py-3" 
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                >
                    <AQOverviewComponent :timeframe="timeframe" :area="areaData" :air_quality="AQDeviceList" :AQdeviceIdAreaMap="AQdeviceIdAreaMap"/>
                </v-card>
            </div>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <div :style="{'text-align':'center'}">
                    <v-icon small>mdi-hail</v-icon>
                    <h4 :style="{'color':$store.getters.getColorPalette().accentCode ,'margin-top':'10px','text-align':'center'}">Not Configured</h4>
                    <h5 :style="{'color':$store.getters.getColorPalette().accentCode ,'margin-top':'10px','text-align':'center'}">Right Click to Configure</h5>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-dialog v-model="dialog" transition="dialog-bottom-transition">
                    <AQAreaForm ref="aqAreaForm" :multiple="multipleAreas" :areaData="areaData" v-on:value="updateData" v-on:close="dialog=!dialog"/>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import LoadingComponent from '@/components/LoadingComponent'
import EditViewBottomSheet from '@/components/modals/EditViewBottomSheet'
import AQAreaForm from '@/components/forms/AQAreaForm.vue'
import axios from 'axios'
import AQInsightsByAreaComponent from '@/components/aqView/AQInsightsByAreaComponent.vue'
import AreaWiseAQIComponent from '@/components/aqView/AreaWiseAQIComponent.vue'
import AQIHistoricalDataTableComponentVue from '@/components/aqView/AQIHistoricalDataTableComponent.vue'
import PollutantsGraphComponent from '@/components/aqView/PollutantsGraphComponent.vue'
import AQOverviewComponent from '@/components/aqView/AQOverviewComponent.vue'
export default {
    name:'AQViewPage',
    props:{
        'view':{
            type:Object
        },
        showEdit:{
            type:Boolean,
            default:true
        },
        edit:{
            type:Boolean,
            default:true
        },
        settingOptions:{
            type:Boolean,
            default:true
        }
    },
    components:{
        InfoAlert,
        EditViewBottomSheet,
        LoadingComponent,
        DeleteConfirmation,
        AQAreaForm,
        AQInsightsByAreaComponent,
        AreaWiseAQIComponent,
        AQIHistoricalDataTableComponentVue,
        PollutantsGraphComponent,
        AQOverviewComponent
    },
    data(){
        return {
            enableScrollMode: false,
            showDialog:false,
            loading:false,
            info:'',
            seq:0,
            x:0,
            y:0,
            dialog:false,
            showComponent:false,
            AQdeviceIdAreaMap:null,
            AQDeviceList:[],
            showDismissibleAlert:false,
            showMenu:false,
            refresh:'',
            areaData:null,
            editMode:this.edit,
            multipleAreas:true,
            timeframe:'6',
            timeframeOptions:[
                {label:'last 7 days', value:'6'},
                {label:'last 30 days', value:'29'},
            ],
            temp:false
        }
    },
    mounted(){
        this.init()
        let temp = localStorage.getItem('enableScroll') 
        if(temp == 'true'){
            this.enableScrollMode = true
        }else{
            this.enableScrollMode=false
        }
    },
    methods:{
        init(){
            if(this.view && this.view.view_id && this.view.data){
                this.areaData=this.view.data
                this.multipleAreas=this.view.meta && this.view.meta.multipleAreas!==null?this.view.meta.multipleAreas:true
                this.fetchAQDeviceIds()
            }else{
                this.areaData=[]
            }
        },
        show (e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        updateData(value){
            this.dialog=false
            this.areaData=value.area
            this.multipleAreas=value.multipleAreas
            if (value.enableScroll !== undefined) {
                this.enableScrollMode = value.enableScroll;
            }
            this.save()
        },
        async fetchAQDeviceIds() {
            this.AQdeviceIdAreaMap = {};
            this.AQDeviceList=[]
            let temp=[]
            try {
                if(this.multipleAreas !==null && this.multipleAreas && this.areaData && this.areaData.length>0){
                    for (let i of this.areaData) {
                        const response = await axios.post(this.$store.state.api + 'getAQByAreaById',{ area_id: i.area_id },{ headers: { Authorization: 'Bearer ' + this.$store.state.jwt }});
                        if (response.data.status === 'success') {
                            let aq_data = response.data.data;
                            for (let k of response.data.data){
                                temp.push(k)
                            }
                            if (!this.AQdeviceIdAreaMap[i.area_id]) {
                                this.AQdeviceIdAreaMap[i.area_id] = [];
                            }
                            this.AQdeviceIdAreaMap[i.area_id] = aq_data;
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    }
                }else{
                    if(this.areaData && this.areaData.area_id){
                        const response = await axios.post(this.$store.state.api + 'getAQByAreaById',{ area_id: this.areaData.area_id },{ headers: { Authorization: 'Bearer ' + this.$store.state.jwt }});
                        if (response.data.status === 'success') {
                            let aq_data = response.data.data;
                            for (let k of response.data.data){
                                temp.push(k)
                            }
                            this.AQdeviceIdAreaMap[this.areaData.area_id] = aq_data;
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    }
                }
                this.AQDeviceList=temp
                this.showComponent=true
            } catch (error) {
                this.loading=false
                console.error("Error while fetching data:", error);
            }
        },
        save(){
            this.loading=true
            let payload={
                view_id:this.view.view_id,
                view_type:'aqView',
                data:this.areaData,
                name:this.view.name,
                meta:{'multipleAreas':this.multipleAreas}
            }
            axios.post(this.$store.state.api+"updateView",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.dialog=false
                    this.$store.dispatch('refreshViews')
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        setAsDefault(){
            this.loading=true
            let payload={view_id:this.view.view_id}
            axios.post(this.$store.state.api+'updateDashboardView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.edit=false                                
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true   
            })
        },
        deleteView(){
            let payload={view_id:this.view.view_id}
            this.loading=true
            axios.post(this.$store.state.api+'deleteView',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.edit=false
                    this.$store.dispatch('refreshViews')
                }
            })
            .catch(err=>{
                console.error(err)
                this.loading=false
            });
        },
        cancel(){
            this.showDialog=false
        },
        confirm(){
            this.showDialog=false
        }
    },
    watch:{
        view(){
            this.init()
        }
    }
}
</script>
<style scoped>
.card-content-scroll {
  animation: scrollCard 30s linear infinite;
}
@keyframes scrollCard {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>